.Statistics-title {
  color: #333333;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 20px;
}

.Statistics-number {
  color: #005172;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}
.Statistics-state {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.Statistics-divider {
  border-right: 1px solid #d7e0e4;
}

.Statistics-clickable {
  cursor: pointer;
}

.Statistics-clickable:hover .Statistics-number,
.Statistics-clickable:hover .Statistics-state {
  color: #009ece;
}

@media (max-width: 768px) {
  .Statistics-state {
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
  }
}
