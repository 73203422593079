.DashboardEmpty {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 100px 20px 30px 20px;
  height: calc(100vh - 130px);
}

.DashboardEmpty h1 {
  font-size: 20px;
  font-weight: 600;
  color: #005172;
}

.DashboardEmpty p {
  font-size: 14px;
  color: #005172;
}

.DashboardEmpty .CreateWorkflow-btn {
  width: 100%;
  margin-top: 20px;
}
