.Workflow-details {
  padding: 20px;
  background-color: #ffffff;
  overflow: auto;
  height: calc(100vh - 70px);
  text-overflow: ellipsis;
}

@media (min-width: 650px) and (max-width: 768px) {
  .Workflow-details .nav-tabs > li {
    text-align: center;
    margin-top: 10px;
  }

  .Workflow-details .nav-tabs > li > a:first-of-type {
    margin-left: 0;
  }
}

@media (max-width: 650px) {
  .Workflow-details .nav-tabs > li {
    text-align: center;
    margin-top: 10px;
  }

  .Workflow-detailsResults {
    margin-top: 10px;
  }

  .AppWrapper-content {
    margin-top: 90px;
  }
}

.Workflow-details .tab-content {
  margin-top: 30px;
}
