.OnboardingSupplierCial-header {
  text-align: left;
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
}

.OnboardingSupplierCial-subtitle {
  padding-right: 60px;
}

@media (max-width: 768px) {
  .OnboardingSupplierCial-subtitle {
    padding-right: 0;
  }
}

.OnboardingSupplierCial-show-form-button {
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

.OnboardingSupplierCial-logo {
  width: 300px;
  display: block;
  margin: 20px 0 30px 0;
}

.OnboardingSupplierCial-footer {
  text-align: center;
}

.OnboardingSupplierCial-btn-create-account {
  margin-top: 6px;
}
