.Suppliers {
  padding: 20px;
  overflow: auto;
  height: calc(100vh - 70px);
}

.Suppliers .Suppliers-btn-group {
  position: relative;
  margin-top: 16px;
}

.Suppliers .Suppliers-btn-group .dropdown-toggle.btn-default > .caret {
  margin-left: 0;
  margin-right: 4px;
}
