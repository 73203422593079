.Btn-filter {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 6px 10px !important;
  background-color: #f2f2f2 !important;
  border-radius: 2px 0 0 2px !important;
  margin-left: 10px !important;
}

.Btn-filter:hover,
.Btn-filter:active {
  background-color: #d8d8d8 !important;
}

.Btn-filter.active {
  padding: 6px 6px 6px 10px !important;
  background-color: #e4e4e4 !important;
  min-width: 120px !important;
}

.Btn-filter.active:hover,
.Btn-filter.active:active {
  background-color: #d8d8d8 !important;
}

.Btn-remove-filter {
  margin-left: 0 !important;
  font-size: 10px !important;
  padding: 0 7px 0 6px !important;
  background-color: #e4e4e4 !important;
  border-radius: 0 2px 2px 0 !important;
}

.Btn-remove-filter:hover,
.Btn-remove-filter:active {
  background-color: #d8d8d8 !important;
}

.Modal-filter label.input-control {
  padding-left: 30px;
}

.Modal-filter label {
  margin-bottom: 10px;
}

.Modal-filter .row {
  margin-top: 20px;
  margin-bottom: 24px;
}

.Modal-filter .Square-badge {
  display: inline-block;
}

.Modal-filter .Modal-Financial-strength label.input-control {
  display: block;
}

.Modal-filter .Modal-Financial-strength span.checkbox {
  top: -7px;
}

.Modal-filter .Modal-status label.input-control,
.Modal-filter .Modal-Risk-indicator label.input-control {
  display: inline-block;
  margin-right: 35px;
  margin-top: 10px;
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .Modal-filter .modal-dialog {
    width: 800px;
  }
}

.Modal-filter .modal-footer {
  padding: 20px 15px 20px 15px;
}

.Modal-filter .modal-body {
  padding: 10px 20px;
}

.Modal-filter .modal-content {
  border-radius: 0;
}

.Modal-filter .Close-modal-icon {
  margin-top: -23px;
  cursor: pointer;
  color: #005172;
}
