.AppWrapper-content {
  margin-top: 70px;
}

.Navigation-animated {
  transition: left 0.3s;
}

.Container-animated {
  transition: all 0.3s;
}

.Sidebar-hidden {
  left: -25%;
}
.Sidebar-visible {
  left: 0;
}
