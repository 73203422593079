@font-face {
  font-family: 'fontello';
  src: url('../_fonts/fontello.eot?35084249');
  src: url('../_fonts/fontello.eot?35084249#iefix') format('embedded-opentype'),
    url('../_fonts/fontello.woff2?35084249') format('woff2'),
    url('../_fonts/fontello.woff?35084249') format('woff'),
    url('../_fonts/fontello.ttf?35084249') format('truetype'),
    url('../_fonts/fontello.svg?35084249#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?35084249#fontello') format('svg');
  }
}
*/

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-ic_archive:before {
  content: '\e800';
} /* '' */
.icon-ic_archive2:before {
  content: '\e801';
} /* '' */
.icon-ic_backarrow:before {
  content: '\e802';
} /* '' */
.icon-ic_bell:before {
  content: '\e803';
} /* '' */
.icon-ic_bir:before {
  content: '\e804';
} /* '' */
.icon-ic_checkbox:before {
  content: '\e805';
} /* '' */
.icon-ic_columns:before {
  content: '\e806';
} /* '' */
.icon-ic_close:before {
  content: '\e807';
} /* '' */
.icon-ic_download:before {
  content: '\e808';
} /* '' */
.icon-ic_dots:before {
  content: '\e809';
} /* '' */
.icon-ic_dashboard:before {
  content: '\e80a';
} /* '' */
.icon-ic_downloads:before {
  content: '\e80b';
} /* '' */
.icon-ic_expand-arrow:before {
  content: '\e80c';
} /* '' */
.icon-ic_eye-slash:before {
  content: '\e80d';
} /* '' */
.icon-ic_eye:before {
  content: '\e80e';
} /* '' */
.icon-ic_file:before {
  content: '\e80f';
} /* '' */
.icon-ic_clone:before {
  content: '\e810';
} /* '' */
.icon-ic_grip-horizontal:before {
  content: '\e811';
} /* '' */
.icon-ic_hamburger:before {
  content: '\e812';
} /* '' */
.icon-ic_plus:before {
  content: '\e813';
} /* '' */
.icon-ic_print:before {
  content: '\e814';
} /* '' */
.icon-ic_question:before {
  content: '\e815';
} /* '' */
.icon-ic_radio:before {
  content: '\e816';
} /* '' */
.icon-ic_screen:before {
  content: '\e817';
} /* '' */
.icon-ic_talk-to-us:before {
  content: '\e818';
} /* '' */
.icon-ic_toast-info:before {
  content: '\e819';
} /* '' */
.icon-ic_toast-success:before {
  content: '\e81a';
} /* '' */
.icon-ic_trash:before {
  content: '\e81b';
} /* '' */
.icon-ic_toast-warn:before {
  content: '\e81c';
} /* '' */
.icon-ic_workflows-active:before {
  content: '\e81d';
} /* '' */
.icon-ic_workflows-closed:before {
  content: '\e81e';
} /* '' */
.icon-ic_search:before {
  content: '\e81f';
} /* '' */
.icon-ic_workflows-draft:before {
  content: '\e820';
} /* '' */
