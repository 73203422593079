.Search-bar {
  min-width: 160px;
  max-width: 650px;
  width: calc(70% - 125px);
  display: inline-block;
  margin-top: 12px;
  float: left;
  position: relative;
  transition: all 300ms ease-out;
}

.Search-bar .Search-input {
  width: 100%;
  text-align: left;
  background-color: #f2f2f2;
  color: #333333;
  border: 1px solid #f2f2f2;
}

@media (max-width: 768px) {
  .Search-bar {
    width: 50px;
    min-width: auto;
    max-width: none;
  }
}

.Search-bar .Search-input::placeholder {
  color: #728d96;
}

.Search-bar .Search-input:focus {
  border-color: #cacaca;
  background-color: #ffffff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 2px 1px rgba(0, 0, 0, 0.075);
}

.Search-bar .Search-icon {
  color: #728d96;
  font-size: 18px;
  margin-top: 5px;
  margin-right: 10px;
}
