.Contact {
  background-color: #f1f6f8;
}
.Contact-h3 {
  font-size: 16px;
  color: #005172;
  font-weight: 600;
}

.Contact-h4 {
  font-size: 14px;
  font-weight: 600;
  color: #333333;
}

.Title-paragraph {
  margin-bottom: 30px;
  font-weight: 600;
  color: #005172;
  font-size: 16px;
}

.countries-list {
  margin-bottom: 20px;
}

.Contact-hr {
  margin-top: 30px;
  margin-bottom: 30px;
  color: #d7e0e4;
}

.Chat-button:hover,
.Chat-button:active {
  color: #ffffff;
  background-color: #0088b1;
}

.Chat-button:disabled {
  color: #ffffff;
  background-color: #b1d8e6;
}

.Contact-form {
  margin-top: 150px;
}

@media (max-width: 768px) {
  .Contact-form {
    margin-top: 100px;
  }
}
