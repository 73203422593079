.WorkflowsListItem {
  height: 150px;
  padding: 0;
  margin-bottom: 16px;
  border: 1px solid #d7e0e4;
}
.WorkflowsListItem-image-container {
  padding-left: 0;
  height: 150px;
  cursor: pointer;
}

.WorkflowsListItem-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.WorkflowsListItem-no-cover {
  background: #d7e0e4 url('../../../_images/bg-cover-onboarding.jpg') no-repeat
    center;
  background-position: left 0px top -25px;
  background-size: cover;
}

.Workflow-list-title {
  color: #005172;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.Workflow-list-description {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.WorkflowsListItem-content-group {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 18px;
}

.WorkflowsListItem-content {
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.Workflow-list-date {
  padding-left: 10px;
}

.Workflow-list-item-label {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}

.Workflow-list-item-var {
  color: #333333;
  font-size: 14px;
  margin-top: 2px;
}

.Workflow-list-item-description {
  width: 100%;
  max-height: 25px;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.WorkflowsListItem-content-group {
  padding-right: 30px;
}

.Workflow-list-item-item {
  display: inline-block;
  margin-right: 40px;
}

.WorkflowsListItem-content.WorkflowsListItem-content-main {
  display: block;
  margin-bottom: 16px;
}

.WorkflowsListItem-content-main .Workflow-list-item-item {
  display: block;
}

.WorkflowsListItem-content-main .Workflow-list-item-item div {
  display: inline-block;
}

.WorkflowsListItem-content-main
  .Workflow-list-item-item
  .Workflow-list-item-var {
  display: inline-block;
  font-weight: 600;
}

.WorkflowsListItem-content-main
  .Workflow-list-item-item
  .Workflow-list-item-var.Workflow-list-item-var-title {
  cursor: pointer;
  font-size: 18px;
}

.WorkflowsListItem-content.WorkflowsListItem-content-main
  .Workflow-list-item-label {
  color: #333333;
}

.WorkflowsListItem-content .Workflow-list-item-label {
  color: #888888;
}

.WorkflowsListItem-content div:last-child {
  margin-right: 0;
}
