.Statistics {
  padding: 20px;
}

.Statistics-header {
  color: #005172;
  font-size: 20px;
  padding-left: 15px;
  padding-bottom: 15px;
}

.Statistics-content {
  padding: 20px;
  border: 1px solid #d7e0e4;
  margin-bottom: 20px;
}

.Statistics-content-row {
  height: 393px;
}

@media (max-width: 768px) {
  .Statistics-content {
    margin-bottom: 8px;
  }
}

.RecentActivity-container {
  overflow-y: auto;
  height: 93%;
}

.RecentActivity-notification:hover,
.RecentActivity-notification:active {
  background-color: #f5f5f5;
}
