.StatisticsCountry {
  border-bottom: 1px solid #d7e0e4;
  margin-left: 2px;
  margin-right: 20px;
}

.StatisticsCountry.clickable {
  cursor: pointer;
}

.StatisticsCountry.clickable:hover {
  opacity: 0.8;
}

.StatisticsCountry-square {
  float: left;
  position: relative;
  min-width: 47px;
  height: 47px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 3px;
}

.StatisticsCountry-square.StatisticsCountry-square-level-1 {
  background-color: #005172;
}

.StatisticsCountry-square.StatisticsCountry-square-level-2 {
  background-color: #276282;
}

.StatisticsCountry-square.StatisticsCountry-square-level-3 {
  background-color: #3d7490;
}

.StatisticsCountry-square.StatisticsCountry-square-level-4 {
  background-color: #54859e;
}

.StatisticsCountry-square.StatisticsCountry-square-level-5 {
  background-color: #6c97ab;
}

.StatisticsCountry-square.StatisticsCountry-square-level-6 {
  background-color: #84a8b9;
}

.StatisticsCountry-Country-Name {
  margin-top: 17px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  width: calc(100% - 57px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.StatisticsCountry-Country-Name-First {
  margin-top: 15px;
}

.StatisticsCountry-square-content {
  color: #ffffff;
  position: absolute;
  height: 80%; /* = 100% - 2*10% padding */
  width: 100%; /* = 100% - 2*5% padding */
  padding-top: 30%;
  text-align: center;
}
