.DashboardStatistics {
  padding: 20px;
}

.DashboardStatistics .DashboardStatistics-header {
  color: #005172;
  font-size: 20px;
  padding: 0;
  margin: 0;
  line-height: 32px;
}

.DashboardStatistics
  .DashboardStatistics-workflows-dropdown.open
  .dropdown-toggle {
  color: #005172;
}

.DashboardStatistics
  .DashboardStatistics-workflows-dropdown
  input[type='checkbox'] {
  height: inherit;
}

.DashboardStatistics .DashboardStatistics-workflows-dropdown {
  max-width: 100%;
}

.DashboardStatistics .DashboardStatistics-workflows-dropdown .dropdown-toggle {
  text-align: right;
  width: 100%;
}

.DashboardStatistics
  .DashboardStatistics-workflows-dropdown
  .dropdown-toggle
  span {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
}

.DashboardStatistics
  .DashboardStatistics-workflows-dropdown
  .dropdown-toggle
  .caret {
  height: 22px;
}

.DashboardStatistics .DashboardStatistics-Dropdown-item {
  word-break: break-word;
  padding: 8px 10px 8px 10px;
  width: 250px;
  border-bottom: 1px solid #ccc;
}

.DashboardStatistics .DashboardStatistics-workflows-dropdown .dropdown-toggle,
.DashboardStatistics .DashboardStatistics-Dropdown-item label {
  font-weight: 500;
}

.DashboardStatistics .DashboardStatistics-workflows-dropdown {
  margin-left: 10px;
  margin-top: -10px;
}

.DashboardStatistics-workflows-dropdown .dropdown-toggle.btn-default {
  font-size: 14px;
}

.DashboardStatistics
  .DashboardStatistics-workflows-dropdown
  .dropdown-toggle
  .caret {
  height: 16px;
}
