.WorkflowsDetailsResults {
  padding: 20px;
}

.WorkflowsDetailsResults-content {
  padding: 20px;
  border: 1px solid #d7e0e4;
  margin-bottom: 20px;
}

.Results-title {
  color: #333333;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 20px;
}

.Results-number {
  color: #005172;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.Results-state {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.Vertical-divider {
  border-right: 1px solid #d7e0e4;
}

.WorkflowsDetailsResults-btn-group {
  position: relative;
  margin-top: -50px;
  margin-right: 0;
}

.WorkflowsDetailsResults-Close-workflow-xs {
  margin-top: -50px;
  padding: 6px 20px;
}

@media (max-width: 768px) {
  .WorkflowsDetailsResults {
    padding: 20px 10px 20px 10px;
  }

  .Workflows .col-sm-6,
  .Workflows .col-sm-12 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .WorkflowsDetailsResults-content {
    margin-bottom: 8px;
  }
}

.WorkflowActive-btn-clone,
.WorkflowActive-btn-preview {
  font-size: 16px !important;
  padding: 6px 8px !important;
}

@media (max-width: 750px) {
  .WorkflowActive-btn-edit,
  .WorkflowActive-btn-close,
  .WorkflowActive-btn-preview,
  .WorkflowActive-btn-clone {
    display: none;
  }

  .WorkflowsDetailsResults-btn-group button#workflow-create-actions.Dots-icon {
    font-size: 32px;
    color: #005172;
    margin-top: -10px;
    padding-right: 0;
  }
}

@media (min-width: 750px) {
  .WorkflowsDetailsResults-btn-group .Dots-icon {
    display: none;
  }
}
