.WorkflowsDetailsSuppliers {
  margin-left: 0;
  margin-right: 0;
  margin-top: 30px;
  padding: 0;
}

.WorkflowsDetailsSuppliers .WorkflowsDetailsSuppliers-btn-group {
  position: relative;
  margin-top: 16px;
}

.WorkflowsDetailsSuppliers .Add-suppliers-btn,
.WorkflowsDetailsSuppliers .Download-export-btn {
  margin-left: 10px !important;
}

.WorkflowsDetailsSuppliers .Download-export-btn {
  font-size: 20px !important;
  padding: 3px !important;
  color: #ffffff !important;
}

.WorkflowsDetailsSuppliers .Download-export-btn.dropdown.open {
  background-color: #009a92;
}

.WorkflowsDetailsSuppliers .Download-export-btn.btn-default:active:hover,
.WorkflowsDetailsSuppliers .Download-export-btn.btn-default.active:hover,
.WorkflowsDetailsSuppliers
  .Download-export-btn.open
  > .dropdown-toggle.btn-default:hover,
.WorkflowsDetailsSuppliers .Download-export-btn.btn-default:active:focus,
.WorkflowsDetailsSuppliers
  .Download-export-btn.open
  > .dropdown-toggle.btn-default:focus,
.WorkflowsDetailsSuppliers .Download-export-btn.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus,
.WorkflowsDetailsSuppliers .Download-export-btn .dropdown-toggle.btn-default {
  font-size: 20px;
  color: #ffffff !important;
  border-color: transparent;
}

.WorkflowsDetailsSuppliers .Download-export-btn .dropdown-toggle.btn-default {
  font-size: 20px;
  padding: 0;
  color: #ffffff !important;
}

.WorkflowsDetailsSuppliers .Add-suppliers-btn {
  padding: 4px 5px 6px 5px !important;
  font-size: 18px !important;
}

@media (max-width: 1200px) {
  .WorkflowsDetailsSuppliers .Download-export-btn {
    display: none;
  }
}

@media (max-width: 768px) {
  .WorkflowsDetailsSuppliers {
    margin-left: 0;
    margin-right: 0;
  }

  .WorkflowsDetailsSuppliers .Add-suppliers-btn .icon-ic_plus:before {
    margin-right: 2px;
  }
}

.WorkflowsDetailsSuppliers
  .WorkflowsDetailsSuppliers-btn-group
  .Add-suppliers-btn
  > .icon-ic_plus:before {
  margin-right: 0.2em;
}

.WorkflowsDetailsSuppliers .Btn-default-small > .icon-ic_downloads:before {
  margin-right: 3px;
}

.WorkflowsDetailsSuppliers
  .WorkflowsDetailsSuppliers-btn-group
  .dropdown-toggle.btn-default
  > .caret {
  margin-left: 0;
  margin-right: 4px;
}

.WorkflowsDetailsSuppliers .icon-ic_dots {
  cursor: pointer;
  font-size: 18px;
  color: #888888;
}

.WorkflowsDetailsSuppliers .icon-ic_dots:hover {
  color: #333333;
}

@media (max-width: 768px) {
  .WorkflowsDetailsSuppliers .Add-suppliers-btn {
    padding: 10px 10px 6px 10px;
  }
}
