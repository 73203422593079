.Onboarding-footer {
  height: 120px;
  background-color: #003c53;
  margin-top: 80px;
  display: flex;
  align-items: center;
}

.Onboarding-footer .Onboarding-footer-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ffffff;
  margin-right: 130px;
  width: 100%;
}

.Onboarding-footer .Onboarding-footer-content .Links {
  font-size: 12px;
}

.Onboarding-footer .Social {
  right: 0;
  margin-left: 50px;
}

.Onboarding-footer .Social a:last-child {
  margin-left: 16px;
}

.Onboarding-footer .media-icons {
  width: 26px;
  height: 26px;
}

.Onboarding-footer .Logo-cialdnb {
  width: 220px;
  margin-left: 30px;
  margin-right: auto;
}

.Onboarding-footer .Onboarding-footer-content span {
  color: #ffffff;
}

.Onboarding-footer .Onboarding-footer-content a:hover {
  color: #00b2a9;
}

.Onboarding-footer .Links a {
  color: #ffffff;
  margin-left: 20px;
}

.Onboarding-footer a.footer-link-no-margin {
  margin-left: 0 !important;
}

@media (max-width: 884px) {
  .Onboarding-footer .Onboarding-footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    padding-left: 30px;
  }

  .Onboarding-footer .Social {
    margin-top: 20px;
    margin-left: 0;
  }

  .Onboarding-footer {
    height: 170px;
  }

  .Onboarding-footer .Logo-cialdnb {
    margin: 0 0 6px 0;
  }
}
