TagsDropdown__control {
  height: 52px !important;
  position: absolute !important;
  transition: all 200ms !important;
  border: none !important;
  width: 100% !important;
}

.TagsDropdown__placeholder {
  color: #999999 !important;
}

.TagsDropdown__multi-value__label {
  font-size: 14px !important;
  padding: 4px 0 4px 4px !important;
  color: #ffffff !important;
}

.TagsDropdown__multi-value {
  padding: 0 6px !important;
  border-radius: 20px !important;
  font-weight: 400 !important;
}

.TagsDropdown__multi-value__remove {
  padding-left: 0 !important;
  padding-right: 0 !important;
  color: rgba(255, 255, 255, 0.8) !important;
}

.TagsDropdown__multi-value__remove:hover {
  color: #ffffff !important;
  background-color: transparent !important;
  cursor: pointer;
}

.TagsDropdown__multi-value__remove svg {
  width: 20px;
  height: 20px;
}

.TagsDropdown__value-container {
  overflow-y: auto !important;
  height: 45px;
}

.TagsDropdown__input,
.TagsDropdown__input input {
  height: 28px !important;
  margin-top: -1px !important;
}

.TagsDropdown__control.TagsDropdown__control--is-focused,
.TagsDropdown__control.TagsDropdown__control--is-focused
  .TagsDropdown__value-container {
  z-index: 1001;
  min-height: 43px;
  max-height: 120px;
  height: auto;
  transition: all 200ms;
}

.TagsDropdown__control.TagsDropdown__control--is-focused
  .TagsDropdown__value-container {
  padding: 4px 8px 6px 8px;
}

TagsDropdown__control.TagsDropdown__control--is-focused {
  border-color: #66afe9 !important;
  border-radius: 2px !important;
  outline: 0 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6) !important;
}

.TagsDropdown__indicators {
  display: none !important;
}

.TagsDropdown-option {
  display: block;
  padding: 8px 10px;
}

.TagsDropdown-option:hover {
  background-color: #f2f2f2;
}

.TagsDropdown-create-option {
  color: hsl(0, 0%, 60%);
  padding: 8px 12px;
  cursor: pointer;
}

.TagsDropdown-create-option.icon-ic_plus:before {
  margin-right: 8px;
}

.TagsDropdown-create-option:hover {
  background-color: #f2f2f2;
}
