.WorkflowsSuppliersAdd-panel {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
  padding: 30px;
}

.WorkflowsSuppliersAdd .table-responsive {
  clear: both;
  width: 100%;
  overflow-y: visible;
}

.WorkflowsSuppliersAdd #WorkflowsSuppliersAdd-input-fields {
  margin-top: -22px;
}

.WorkflowsSuppliersAdd #WorkflowsSuppliersAdd-input-fields tbody tr:last-child {
  border: none;
}

.WorkflowsSuppliersAdd #WorkflowsSuppliersAdd-input-fields .Select {
  margin-top: 0;
}

.WorkflowsSuppliersAdd form p {
  margin: 20px 0;
  color: #003c53;
}

.WorkflowsSuppliersAdd form textarea {
  min-height: 231px;
  border: 1px solid #d7e0e4;
  border-radius: 2px;
}

.WorkflowsSuppliersAdd form .BulkAdd-btn {
  margin-top: 20px;
}

.WorkflowsSuppliersAdd .alert {
  margin-top: 20px;
}

.WorkflowsSuppliersAddSingleForm {
  margin-top: 20px;
}

.WorkflowsSuppliersAdd {
  background-color: #ffffff;
  height: calc(100vh - 70px);
  padding: 20px;
}

.WorkflowsSuppliersAdd .Search-bar {
  margin-top: 0;
  margin-left: 0;
}

.SupplierAdd-tabs {
  margin-top: 40px;
}

.SupplierAdd {
  margin-left: 0;
  margin-right: 0;
  margin-top: 30px;
  padding: 0;
}

#WorkflowsSuppliersAdd-input-fields tr td {
  width: 33.33%;
}

.SupplierAdd .table {
  margin-bottom: 0;
}

.SupplierAdd .table tr td .form-control {
  margin-bottom: 0;
}

.Select-placeholder {
  line-height: 42px;
}

.WorkflowsSuppliersAddSingleForm button[type='submit'],
.WorkflowsSuppliersAddBulk button[type='submit'] {
  height: 36px;
  margin-left: 0;
}

.SupplierAdd .nav-tabs {
  margin-left: 0;
}

.WorkflowsSuppliersAdd .table-responsive .table {
  table-layout: fixed;
}

.WorkflowsSuppliersAddSingleForm .WorkflowsSuppliersAddSingleForm table {
  table-layout: auto;
}

.WorkflowsSuppliersAdd .table-responsive tbody tr td {
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.WorkflowsSuppliersAdd .Public-link-wrapper {
  margin-bottom: 24px;
  margin-top: -38px;
  position: relative;
}

.WorkflowsSuppliersAdd .Public-link-wrapper button.btn-forgot-pass {
  border: none;
  margin-bottom: 10px;
}

.WorkflowsSuppliersAdd .Public-link-wrapper button.btn-forgot-pass:focus {
  outline: none;
}

.WorkflowsSuppliersAdd .Public-link-wrapper button.btn-forgot-pass:hover {
  color: #0088b1;
  text-decoration: underline;
}

.Link-copied {
  background-color: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  padding: 12px 16px;
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 1;
  transition: all 400ms ease-out;
  opacity: 0;
}

.Link-copied.Fade {
  opacity: 1;
  transition: all 200ms ease-in;
}

.Link-copied .Onboarding-public-link {
  font-size: 11px;
  margin-top: 4px;
}

.Link-copied i {
  margin-right: 4px;
}

.Link-copied span {
  font-weight: 600;
}

@media (max-width: 768px) {
  .WorkflowsSuppliersAdd {
    margin-top: 45px;
  }

  #WorkflowsSuppliersAdd-input-fields tr td {
    width: 100%;
    display: block;
  }
}
