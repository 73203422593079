.NavigationMenu {
  background-color: #003c53;
  height: 100vh;
  padding-top: 20px;
  position: fixed;
  z-index: 1002;
}

.NavigationMenu-list {
  list-style-type: none;
}

.NavigationMenu-list > li > a,
.NavigationMenu .NavigationMenu-list-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #d5dee3;
  text-decoration: none;
  padding: 10px 0 10px 20px;
}

.NavigationMenu-list > li > a:hover,
.NavigationMenu-list > li > a:focus,
.NavigationMenu-list > li > a.active {
  background-color: transparent;
  color: #00d2c7;
}

.NavigationMenu-list i {
  font-size: 18px;
  margin-right: 20px;
}

.NavigationMenu-list .icon-ic_dashboard {
  font-size: 16px;
}

.NavigationMenu-logo {
  height: 38px;
  max-width: 100%;
  margin-top: -10px;
  margin-bottom: 10px;
}

.NavigationMenu .nav > li > a > img {
  max-width: 100%;
}

.wrapper {
  display: flex;
  width: 100%;
}

.Btn-default-small.Navigation-create-btn {
  padding: 4px 10px;
  margin-top: 20px;
  margin-bottom: 16px;
  margin-left: 20px !important;
}

.NavigationMenu-list .Navigation-create-btn i {
  font-size: 14px;
  margin-right: 0;
}

.NavigationMenu li.NavigationMenu-list-title {
  padding-left: 20px;
  color: #b0c2c9;
}

.NavigationMenu .fixed-bottom {
  position: absolute;
  bottom: 50px;
}

.NavigationMenu .fixed-bottom .tos {
  margin-left: 20px;
  margin-top: 15px;
}

.NavigationMenu .fixed-bottom .tos a {
  font-size: 12px;
  color: #d5dee3;
}

.NavigationMenu .fixed-bottom .tos a:hover {
  color: #00d2c7;
}

.NavigationMenu .fixed-bottom .tos span.middot {
  color: #d5dee3;
  margin: 0 7px;
}

@media (max-height: 460px) {
  .NavigationMenu .fixed-bottom {
    display: none;
  }
}
