h1.Bir-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  color: #333333;
  margin-bottom: 30px;
}

.OnboardingSupplierBir {
  margin-top: 30px;
}

.OnboardingSupplierBir .Btn-default-small {
  margin-left: 0 !important;
}

.GeneratedForm .form-group label {
  margin-bottom: 10px;
}

.GeneratedForm h3 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
  margin-top: 30px;
}

.GeneratedForm label {
  color: #333333;
}
