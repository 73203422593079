.CircularProgressbar {
  width: 96px;
  height: 96px;
  margin: 10px;
}

.CircularProgressbar-Industry-1.CircularProgressbar .CircularProgressbar-path {
  stroke: #00b2a9;
}

.CircularProgressbar-Industry-1.CircularProgressbar .CircularProgressbar-text {
  fill: #00b2a9;
}

.CircularProgressbar-Industry-2.CircularProgressbar .CircularProgressbar-path {
  stroke: #009dce;
}

.CircularProgressbar-Industry-2.CircularProgressbar .CircularProgressbar-text {
  fill: #009dce;
}

.CircularProgressbar-Industry-3.CircularProgressbar .CircularProgressbar-path {
  stroke: #bd10e0;
}

.CircularProgressbar-Industry-3.CircularProgressbar .CircularProgressbar-text {
  fill: #bd10e0;
}

.CircularProgressbar-Industry-4.CircularProgressbar .CircularProgressbar-path {
  stroke: #f5a623;
}

.CircularProgressbar-Industry-4.CircularProgressbar .CircularProgressbar-text {
  fill: #f5a623;
}

.StatisticsIndustry-padding-right {
  padding-right: 0;
  padding-left: 20px;
}

.StatisticsIndustry-padding-left {
  padding-right: 20px;
  padding-left: 0;
}

/* QUARTERS */
.StatisticsIndustry-quarter-1 {
  border-right: 1px solid #d7e0e4;
  border-bottom: 1px solid #d7e0e4;
  padding: 10px;
}

.StatisticsIndustry-quarter-2 {
  border-bottom: 1px solid #d7e0e4;
  padding: 10px;
}

.StatisticsIndustry-quarter-3 {
  border-right: 1px solid #d7e0e4;
  padding: 10px;
}

.StatisticsIndustry-quarter-4 {
  padding: 10px;
}

/* HALFS */
.StatisticsIndustry-half-1 {
  border-right: 1px solid #d7e0e4;
  margin-top: 40px;
  padding: 10px;
}

.StatisticsIndustry-half-2 {
  margin-top: 40px;
  padding: 10px;
}

.StatisticsIndustry-half-1 .CircularProgressbar,
.StatisticsIndustry-half-2 .CircularProgressbar {
  width: 140px;
  height: 140px;
  margin: 10px;
}

/* SINGLE */
.StatisticsIndustry-single-1 {
  margin-top: 40px;
  padding: 10px;
}

.StatisticsIndustry-single-1 .CircularProgressbar {
  width: 160px;
  height: 160px;
  margin: 10px;
}

.StatisticsIndustry-Industry-Name {
  font-size: 14px;
  font-weight: 600;
  height: 20px;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.StatisticsIndustry .clickable {
  cursor: pointer;
}

.StatisticsIndustry .clickable:hover {
  opacity: 0.8;
}
