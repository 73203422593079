.Modal-ccr .Close-modal-icon {
  margin-top: -23px;
  cursor: pointer;
  color: #005172;
}

.Modal-ccr .ModalTitle {
  color: #005172;
}

@media (min-width: 992px) {
  .Modal-ccr .modal-dialog {
    width: 800px;
  }
}

.Modal-ccr .modal-content {
  border-radius: 0;
}

.Modal-ccr .Square-badge {
  margin: 0;
}

.Modal-ccr table,
.Modal-ccr th {
  text-align: center;
}

.Modal-ccr .table > tbody > tr > td,
.Modal-ccr .table > thead > tr > th {
  text-align: left;
}

.Modal-ccr .table > tbody > tr > td {
  padding-right: 60px;
}
