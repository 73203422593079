.OnboardingSupplierConfirmation {
  margin-top: 30px;
  text-align: center;
}

.OnboardingSupplierConfirmation h1 {
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  color: #333333;
  margin-top: 30px;
}

.OnboardingSupplierConfirmation p {
  font-size: 14px;
  color: #333333;
}

.OnboardingSupplierConfirmation .OnboardingSupplierConfirmation-phone {
  font-weight: 600;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 16px;
}

.OnboardingSupplierConfirmation .OnboardingSupplierConfirmation-change-phone {
  margin-top: 10px;
  color: #009dce;
  cursor: pointer;
}

.OnboardingSupplierConfirmation
  .OnboardingSupplierConfirmation-change-phone:hover {
  text-decoration: underline;
}

.OnboardingSupplierConfirmation label {
  margin-left: -150px;
  color: #333333;
}

.OnboardingSupplierConfirmation input {
  margin: 10px auto 15px auto;
  max-width: 200px;
}

.OnboardingSupplierConfirmation hr {
  width: 100px;
  margin: 40px auto;
  border: 1px solid #005172;
}

.OnboardingSupplierConfirmation .Btn-outline-small {
  margin-left: 0 !important;
}

@media (min-width: 200px) {
  .OnboardingSupplierConfirmation .Btn-default-primary {
    margin-left: 20px;
  }
}

.OnboardingSupplierConfirmation .alert {
  width: 200px;
  height: 50px;
  margin: 20px auto 0 auto;
}

.OnboardingSupplierConfirmation .Go-back-title {
  margin-top: 30px;
}

.OnboardingSupplierConfirmation .Go-back .Steps {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.OnboardingSupplierConfirmation .Go-back-step {
  border: 1px solid #d8d8d8;
  margin: 0 10px 0 10px;
  padding: 10px;
  border-radius: 2px;
  cursor: pointer;
}

.OnboardingSupplierConfirmation .Go-back-step:hover {
  background-color: #f2f2f2;
}

.OnboardingSupplierConfirmation .Go-back-step.Selected {
  border: 1px solid #d8d8d8;
  background-color: #d8d8d8;
}

.OnboardingSupplierConfirmation .Edit-btn {
  margin-left: 0 !important;
  margin-top: 26px;
}
