.WorkflowsListEmpty {
  height: 120px;
  background-color: #ffffff;
  text-align: center;
  padding: 50px;
  margin-top: 50px;
  border-top: 1px solid #d7e0e4;
  border-bottom: 1px solid #d7e0e4;
}

@media (max-width: 380px) {
  .WorkflowsListEmpty {
    padding: 45px;
  }
}
