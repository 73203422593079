.OnboardingWorkflowClosed {
  display: flex;
  align-items: center;
  height: calc(100vh - 200px);
  justify-content: center;
  flex-direction: column;
}

.OnboardingWorkflowClosed > img,
.OnboardingWorkflowClosed > p {
  margin-bottom: 30px;
}

.OnboardingWorkflowClosed > p {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  color: #005172;
}

.OnboardingWorkflowClosed > img {
  margin-top: 50px;
}

@media (max-width: 1290px) and (min-width: 601px) {
  .OnboardingWorkflowClosed > img {
    width: 350px;
    margin-top: 120px;
  }

  .OnboardingWorkflowClosed > p {
    font-size: 26px;
  }
}

@media (max-width: 600px) and (min-width: 491px) {
  .OnboardingWorkflowClosed > img {
    width: 350px;
    margin-top: 50px;
  }

  .OnboardingWorkflowClosed > p {
    font-size: 26px;
  }
}

@media (max-width: 490px) {
  .OnboardingWorkflowClosed > img {
    width: 300px;
    margin-top: 0;
  }

  .OnboardingWorkflowClosed > p {
    font-size: 26px;
  }
}
