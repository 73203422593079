.Header {
  margin-bottom: 0;
  background-color: #ffffff;
  border: none;
  background-image: none;
  height: 70px;
  padding-bottom: 10px;
  position: fixed;
  z-index: 1001;
}

.Header-wide {
  width: 100%;
}

.Header-nav {
  margin-top: 14px;
  display: inline-block;
}

.Header-nav.navbar-nav > li {
  padding-right: 0;
}

.Header-nav.navbar-nav > li > a {
  color: #005172;
  cursor: pointer;
  text-shadow: none;
}

.Header-navbar-brand {
  padding: 8px;
  margin-top: 10px;
}

.Header-nav.navbar-nav > .active > a,
.Header-nav.navbar-nav > .active > a:hover,
.Header-nav.navbar-nav > .active > a:focus {
  background: none;
  box-shadow: none;
}

.Header-nav .dropdown * {
  cursor: default;
}

.Header-nav .dropdown.open .dropdown-toggle,
.Header-nav .dropdown.open .dropdown-toggle:focus {
  background: #eef9fd;
  box-shadow: none;
}

.Header.navbar-inverse .Header-nav .dropdown-menu {
  width: 320px;
  padding: 0;
  background: #ffffff;
  border: 1px solid #d7e0e4;
  border-radius: 0;
}

.Header-nav .dropdown-menu .Link,
#logout {
  color: #009dce;
  cursor: pointer;
}

.Header-nav .dropdown-menu .Link:hover,
#logout:hover {
  text-decoration: none;
  color: #00b5e6;
}

.Header-nav .dropdown-menu a:hover,
.Header-nav .dropdown-menu a:focus,
.Header-nav .dropdown-menu div:hover,
.Header-nav .dropdown-menu div:focus {
  background: initial;
  box-shadow: none;
  outline: none;
  border: none;
}

.Header-nav .Dropdown-header {
  background-color: #fafbfb;
  color: #003c53;
  font-size: 12px;
  line-height: 17px;
  padding: 3px 0px;
}

.Header-nav .Dropdown-header:hover {
  background: #fafbfb;
}

.Header-nav .Header-with-link div {
  padding: 7px 20px;
}

.Header-nav .Header-with-link .Link {
  float: right;
}

.Header-nav .Dropdown-content {
  padding: 10px 0;
}

.Header-nav .Dropdown-content {
  border-bottom: 1px solid #d7e0e4;
}

.Header-nav .Dropdown-content .Colored-circle {
  border-radius: 100%;
  background: #3e85c1;
  color: #fff;
  display: inline-block;
  line-height: 1.7em;
  font-weight: 400;
  text-align: center;
  width: 62px;
  height: 62px;
  font-size: 25px;
  padding-top: 10px;
}

.Header-nav .Dropdown-content .Colored-circle:hover {
  background: #3e85c1;
}

.Header-nav .Dropdown-content .User-image {
  width: 62px;
  height: 62px;
  border-radius: 100%;
}

#logout {
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  padding-top: 10px;
}

.User-details h4 {
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 0px;
}

.User-details p {
  font-size: 12px;
}

.Company-details h4 {
  margin-top: 3px;
  margin-bottom: 15px;
  font-size: 14px;
}

.Company-details img {
  width: 62px;
  height: 62px;
}

.Company-details .Company-subscription {
  margin-left: 0px;
}

.Company-details .Company-subscription .Subscription-label {
  font-size: 11px;
  margin-bottom: 0px;
}

.Company-details .Company-subscription .Subscription-value {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 3px;
}

@media (max-height: 768px) {
  .Company-subscription {
    height: 150px;
    overflow: auto;
  }
}

.Header .navbar-toggle {
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  margin-top: 22px;
  margin-right: 15px;
}

.Header .navbar-toggle .icon-bar {
  background-color: #005172;
}

.Header .navbar-toggle:hover {
  background-color: #eef9fd;
}

.Header.navbar-inverse .navbar-collapse {
  border-color: transparent;
  text-align: left;
  background-color: #ffffff;
}

.Header .navbar-nav > li > a,
.Header.navbar-inverse .navbar-nav > li > a {
  text-align: left;
  color: #005172;
}

.Header .dropdown-menu li:hover,
.Header .navbar-nav > .active > a:hover,
.Header .navbar-nav > .active > a:focus,
.Header.navbar-inverse .navbar-nav > li > a:hover,
.Header.navbar-inverse .navbar-nav > li > a:focus,
.Header.navbar-inverse .navbar-nav > .open > a,
.Header.navbar-inverse .navbar-nav > .open > a:hover,
.Header.navbar-inverse .navbar-nav > .open > a:focus {
  color: #005172;
  background-color: #ffffff;
}

.Header .Hamburger-dropdown {
  display: inline-block;
  margin-left: 12px;
}

.Header .Hamburger-colored-circle {
  border-radius: 100%;
  background: #3e85c1;
  color: #fff;
  display: inline-block;
  line-height: 1.5em;
  font-weight: 400;
  text-align: center;
  width: 32px;
  height: 32px;
  font-size: 20px;
  padding-bottom: 3px;
  margin-right: 10px;
}

.Header .Hamburger-dropdown-row {
  margin-left: 11px;
  margin-top: 10px;
}

.Header.navbar-inverse .navbar-toggle:focus {
  background-color: transparent;
}

.Header-navitem {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #eee;
}

.Header-navitem.no-border {
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
}

.Header-nav .dropdown-menu .Link {
  font-weight: 600;
}

.Public-navbar > li {
  float: left;
}

.Header .navbar-nav li.Language-selector a {
  margin-right: 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .Header.navbar-inverse {
    left: 0;
    right: 0;
    padding-right: 20px;
    padding-left: 20px;
    margin: 0;
  }

  .Header.navbar-inverse
    .navbar-nav
    .open
    .dropdown-menu
    > li.Edit-account-small-header
    > a {
    color: #005172;
  }

  .Header.navbar-inverse .Header-nav .dropdown-menu .Link,
  #logout {
    font-size: 14px;
    font-weight: 400;
    color: #005172;
  }

  .Header .navbar-nav .open .dropdown-menu {
    background-color: #fff;
    border: 1px solid #d7e0e4;
    box-shadow: 0 6px 0px rgba(0, 0, 0, 0.175);
  }

  .Header.navbar-inverse .Header-nav .dropdown-menu {
    width: auto;
    border: none;
    background-color: transparent;
    box-shadow: none;
  }

  .Header.navbar-inverse .dropdown-menu li {
    border-bottom: none;
    padding: 8px 0;
  }
}

.Header.navbar-fixed-top .navbar-collapse {
  max-height: inherit;
}

.Header.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
  color: #333;
}

@media (min-width: 768px) {
  .Header-nav.navbar-right ~ .navbar-right {
    margin-right: -60px;
  }
}

.Header.navbar > .container .navbar-brand.Navbar-brand,
.Header.navbar > .container-fluid .navbar-brand.Navbar-brand {
  margin-left: -30px;
}

.Header .navbar-nav > li.Public-header-login > a,
.Header .navbar-nav > li > a > .Public-header-contact {
  color: #009ece;
  font-weight: 600;
}

.Header .navbar-nav > li > a > .Public-header-contact {
  margin-left: 30px;
}

.Header .navbar-header {
  margin-top: 18px;
  display: block;
}

.Header.navbar-default {
  box-shadow: none;
}

.Header .breadcrumb {
  display: inline-block;
  margin-bottom: 0;
  background-color: transparent;
  font-size: 18px;
  font-weight: 600;
}

.Header .breadcrumb li a {
  color: #888888;
  font-size: 17px;
}

.Header .breadcrumb > .active {
  color: #005172;
  font-size: 17px;
}

.breadcrumb > li + li:before {
  content: '>';
  color: #888888;
}

.Header .navbar-header .icon-ic_hamburger {
  cursor: pointer;
  background-color: transparent;
  border-radius: 2px;
  padding: 8px 10px 8px 5px;
}

.Header .navbar-header .icon-ic_hamburger:hover {
  background-color: #f2f2f2;
  border-radius: 2px;
  padding: 8px 10px 8px 5px;
}

.Logo-xs {
  height: 38px;
  max-width: 100%;
}

.navbar-header .navbar-collapse {
  width: 100%;
}

@media (max-width: 768px) {
  .Header.navbar-inverse .navbar-collapse {
    width: 100%;
  }

  .Header-nav {
    box-shadow: 0 6px 16px -10px rgba(0, 0, 0, 0.175);
    padding-left: 10px;
  }

  .Public-navbar {
    box-shadow: none;
    margin-right: 20px;
  }

  .Header.navbar-inverse .container-fluid {
    margin-right: -50px;
    margin-left: -25px;
    background-color: #ffffff;
    padding-left: 20px;
  }

  .navbar-collapse {
    overflow-x: hidden;
  }

  .Header .breadcrumb {
    white-space: nowrap;
    width: 70%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  .container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.Header-public .container {
  width: 100%;
}

.Header .dropdown.open .caret {
  transform: rotate(180deg);
  transition: all 250ms ease;
}

.Header .dropdown .caret {
  transition: all 250ms ease;
}

.Header-nav .User-details p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.Btn-default-small.Navigation-create-btn-xs {
  padding: 4px 10px;
  margin-top: 10px;
  margin-bottom: 0;
  margin-left: 0 !important;
}

.NavigationMenu-list .Navigation-create-btn-xs i {
  font-size: 14px;
  margin-right: 0;
}

.Header .User-details h4,
.Header .Company-details h4 {
  white-space: pre-line;
}
