.Dashboard {
  background-color: #ffffff;
  overflow: auto;
  height: 100vh;
}

.Dashboard .dropdown-toggle.btn-default,
.Dashboard .dropdown-toggle.btn-default:active,
.Dashboard .dropdown-toggle.btn-default:hover,
.Dashboard .dropdown-toggle.btn-default:focus {
  text-transform: none;
}

@media (max-width: 768px) {
  .DashboardStatistics {
    padding: 10px 10px 10px;
  }
  .DashboardStatistics-header {
    padding-left: 10px;
  }

  .DashboardStatistics .col-sm-12 {
    padding: 0;
  }

  .DashboardStatistics .col-sm-6 {
    padding: 0;
  }

  .DashboardStatistics-header {
    margin-top: 12px;
    margin-bottom: 0;
  }
}
