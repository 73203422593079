.StatisticsFinancialStrength {
  margin-left: 2px;
  margin-right: 0px;
}

.StatisticsFinancialStrength.clickable {
  cursor: pointer;
}

.StatisticsFinancialStrength.clickable:hover {
  opacity: 0.8;
}

.StatisticsFinancialStrength:nth-of-type(1) {
  margin-top: 10px;
}

.StatisticsFinancialStrength-square {
  float: left;
  position: relative;
  width: 65px;
  height: 38px;
  /*padding-bottom: 10%;*/
  margin: 3px 0 10px 0;
  overflow: hidden;
}

.StatisticsFinancialStrength-square-content {
  position: absolute;
  height: 80%; /* = 100% - 2*10% padding */
  width: 90%; /* = 100% - 2*5% padding */
  max-width: 55px;
  padding: 14% 36%;
  text-align: center;
}

.StatisticsFinancialStrength-square-content-other {
  padding: 14% 20%;
}

.StatisticsFinancialStrength-percentage {
  margin-top: 14px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  float: right;
  width: 55px;
  text-align: center;
}

.StatisticsFinancialStrength-square-inline {
  display: inline-block;
  float: left;
  position: relative;
  height: 38px;
  margin-top: -7px;
  overflow: hidden;
}

.StatisticsFinancialStrength-square-inline-background {
  display: inline-block;
  float: left;
  position: relative;
  height: 38px;
  margin-top: -7px;
  overflow: hidden;
  border: 1px solid #edf1f3;
  background-color: #edf1f3;
}

.StatisticsFinancialStrength-inline-squares-wrapper {
  width: calc(100% - 120px);
  float: left;
  height: 38px;
  margin-top: 10px;
}
