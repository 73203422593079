.WorkflowsSupplierDetails {
  background-color: #ffffff;
  padding: 20px 30px;
}

.WorkflowsSupplierDetails-btn-group {
  position: absolute;
  right: 30px;
  top: 80px;
}

.WorkflowsSupplierDetails-title {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}

.WorkflowsSupplierDetails-label-inline {
  color: #888888;
  font-size: 14px;
  font-weight: 600;
  margin-top: 4px;
  margin-right: 6px;
  display: inline-block;
}

.WorkflowsSupplierDetails-info-text-inline {
  color: #333333;
  font-size: 14px;
  margin-top: 4px;
  font-weight: 600;
  display: inline-block;
  word-break: break-word;
}

.WorkflowsSupplierDetails-label {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}

.WorkflowsSupplierDetails-question {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 14px;
}

.WorkflowsSupplierDetails-info-text {
  color: #002e41;
  font-size: 14px;
  margin-top: 2px;
  margin-bottom: 16px;
}

.WorkflowsSupplierDetails .icon-ic_question {
  cursor: pointer;
}

.WorkflowsSupplierDetails-answer {
  color: #002e41;
  font-size: 14px;
  margin-top: 2px;
  margin-bottom: 12px;
}

.WorkflowsSupplierDetails-question-answer {
  margin-bottom: 26px;
}

.WorkflowsSupplierDetails-rating.Square-badge {
  margin-left: 0;
  margin-top: 6px;
  margin-bottom: 16px;
  padding: 4px 8px;
}

.WorkflowsSupplierDetails-rating.rating-inline {
  margin-bottom: 0;
  display: inline-block;
}

.WorkflowsSupplierDetails-content {
  margin-top: 20px;
}

.WorkflowsSupplierDetails-title-table {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  border-bottom: 1px solid #d7e0e4;
  padding: 16px 20px;
  background-color: #f6f6f6;
  height: 55px;
  display: flex;
  align-items: center;
}

.WorkflowsSupplierDetails-title-table.Screen-title {
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}

.WorkflowsSupplierDetails-content-table {
  border-bottom: 1px solid #d7e0e4;
  padding: 10px 20px 0px 20px;
}

.WorkflowsSupplierDetails-content-table:last-child {
  padding: 20px 20px 0px 20px;
  border-bottom: 0;
}

.WorkflowsSupplierDetails-results-data .WorkflowsSupplierDetails-content-table {
  padding: 20px;
  border-bottom: none;
}

.WorkflowsSupplierDetails-summary-item {
  margin-bottom: 14px;
}

.WorkflowsSupplierDetails-last-update {
  font-size: 12px;
  font-weight: 600;
  color: #888888;
}

.WorkflowsSupplierDetails-status-label {
  background-color: #b0afaf;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 20px;
  padding: 2px 10px;
  margin-left: 16px;
}

.WorkflowsSupplierDetails-border-rectangle {
  border: 1px solid #d7e0e4;
  margin-bottom: 20px;
}

/* ACTIVITY */
.WorkflowsSupplierDetails-connected-list {
  padding-left: 15px;
  margin-top: 10px;
  margin-bottom: 0;
}

.WorkflowsSupplierDetails-connected-list li {
  padding-left: 33px;
  position: relative;
  margin-bottom: 0;
  padding-bottom: 1.5em;
  list-style: none;
}

.WorkflowsSupplierDetails-connected-list li span {
  margin-top: 50px;
}

.WorkflowsSupplierDetails-connected-list li:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: 0;
  top: 5px;
}

.WorkflowsSupplierDetails-connected-list li:nth-child(even):after {
  background: #3fb4ac;
}

.WorkflowsSupplierDetails-connected-list li:nth-child(odd):after {
  background: #009dce;
}

.WorkflowsSupplierDetails-connected-list li:before {
  /* lines */
  content: '';
  position: absolute;
  left: 5px;
  border-left: 1px solid #dddddd;
  height: 100%;
  width: 1px;
}

.WorkflowsSupplierDetails-connected-list li:first-child:before {
  /* first li's line */
  top: 6px;
}

.WorkflowsSupplierDetails-connected-list li:last-child:before {
  /* last li's line */
  height: 6px;
}

.WorkflowsSupplierDetails-status-date {
  margin-left: 10px;
  color: #aaaaaa;
}

/* END ACTIVITY */

@media print {
  @page {
    margin: 1cm;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  .NavigationMenu,
  .Header,
  .WorkflowsSupplierDetails .WorkflowsSupplierDetails-btn-group,
  .WorkflowsSupplierDetails-results.no-results,
  .FileUpload,
  .FileDescriptor .FileDescriptor-btn-delete,
  .Download-export-btn,
  .icon-ic_expand-arrow,
  .Btn-request-cfr,
  .Btn-download-report {
    display: none;
  }

  .Workflows {
    overflow: hidden;
  }

  .AppWrapper-content {
    margin-top: 0;
  }

  .Container-animated {
    margin-left: 0;
  }

  .WorkflowsSupplierDetails-rectangle {
    page-break-inside: avoid;
  }

  .WorkflowsSupplierDetails-summary,
  .WorkflowsSupplierDetails-results-data {
    page-break-after: always;
  }
}

.WorkflowsSupplierDetails-content-table .FileDescriptor {
  margin-bottom: 10px;
}

.WorkflowsSupplierDetails-content-table
  .FileDescriptor
  .FileDescriptor-btn-delete {
  line-height: inherit;
}

.WorkflowsSupplierDetails-title-table .FileUpload {
  margin-left: auto !important;
  margin-top: 4px;
}

.WorkflowsSupplierDetails-title-table .FileUpload.uploading {
  margin-top: 15px;
}

.WorkflowsSupplierDetails-title-table .FileUpload .FileUpload-uploading-text {
  font-size: 14px;
}

.WorkflowsSupplierDetails-content-table div.Empty-state {
  color: #9b9b9b;
  margin-bottom: 16px;
  margin-top: 10px;
}

.WorkflowsSupplierDetails-labels .Badge {
  margin-bottom: 10px;
  margin-right: 10px;
}

.WorkflowsSupplierDetails .Screen-label {
  margin-bottom: 38px;
}

.WorkflowsSupplierDetails .WorkflowsCreate-panel.Screen-panel .panel-title > a {
  padding: 0;
}

.WorkflowsSupplierDetails
  .WorkflowsCreate-panel.Screen-panel
  .icon-ic_expand-arrow {
  margin-top: -36px;
}

.WorkflowsSupplierDetails .WorkflowsCreate-panel .panel-heading a {
  text-decoration: none;
}

.WorkflowsSupplierDetails
  .WorkflowsCreate-panel
  .panel-heading
  a:hover
  .WorkflowsSupplierDetails-title-table-heading {
  text-decoration: underline;
}

.WorkflowsSupplierDetails .WorkflowsCreate-panel.Screen-panel .panel-body {
  padding: 0 15px;
}

.WorkflowsSupplierDetails .panel-group .panel.Screen-panel {
  margin-top: 0;
}

.Download-files-btn .dropdown-toggle.btn-default {
  font-size: 14px;
  color: #ffffff;
  padding: 2px;
  font-weight: normal;
}

.Download-files-btn .dropdown-toggle.btn-default:hover,
.Download-files-btn .dropdown-toggle.btn-default:focus {
  color: #ffffff;
}

.WorkflowsSupplierDetails-ccr-score {
  margin-top: 6px;
}

.WorkflowsSupplierDetails-ccr-score div.Rating-badge {
  margin: 0;
}

.Not-requested-state {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Not-requested-state button {
  margin-left: 0;
  margin-top: 16px;
}

.Screen-pending-state {
  position: relative;
  padding: 50px 40px 40px 40px;
  display: flex;
  justify-content: center;
}

.WorkflowsSupplierDetails
  .WorkflowsCreate-panel.Screen-panel
  .ScreenPanelBodyWrapper.panel-body {
  padding: 0;
}

.WorkflowsSupplierDetails
  .WorkflowsCreate-panel.Screen-panel
  .ScreenPanelBodyWrapper
  .panel-group {
  margin-bottom: 0;
}

.ScreenPanelBodyWrapper .WorkflowsCreate-panel.panel.panel-default {
  margin-bottom: 0;
}

.ScreenPanelBodyWrapper
  .panel-default
  > .panel-heading
  + .panel-collapse
  > .panel-body {
  border: none;
}

.Results-status {
  position: absolute;
  right: 70px;
  margin-top: 2px;
}

.WorkflowsSupplierDetails-content-table.Bir-content-table .Not-requested-state {
  padding: 20px 0 20px 0;
}

.WorkflowsSupplierDetails-content-table .table-responsive {
  padding-left: 10px;
  padding-right: 10px;
}

.WorkflowsSupplierDetails-content-table .table-responsive td {
  color: #333333;
}

.WorkflowsSupplierDetails-content-table .table-responsive tr td:first-child {
  text-align: left;
  width: 40%;
}

.WorkflowsSupplierDetails-content-table .table-responsive tr td {
  text-align: center;
  width: 30%;
}

.WorkflowsSupplierDetails-content-table .table-responsive tr td:last-child {
  padding-right: 50px;
  text-align: right;
  width: 30%;
}

.WorkflowsCreate-panel
  .WorkflowsSupplierDetails-content-table
  .table-responsive
  tbody
  tr {
  border-bottom: 1px solid #d7e0e4;
  height: 55px;
}

.ScreenPanelBodyWrapper
  .WorkflowsSupplierDetails-results-data
  .WorkflowsSupplierDetails-content-table {
  padding: 0 20px 0 15px;
}

.ScreenPanelBodyWrapper
  .WorkflowsCreate-panel
  .WorkflowsSupplierDetails-content-table
  .table-responsive
  table {
  margin-bottom: 26px;
}

.Screen-panel .collapsed .WorkflowsSupplierDetails-title-table.Screen-title {
  background-color: #ffffff;
}

.Screen-panel .WorkflowsSupplierDetails-title-table.Screen-title {
  background-color: #f6f6f6;
}

.WorkflowsSupplierDetails-content-table .table-responsive tr td ul {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .WorkflowsSupplierDetails-content-table
    .table-responsive
    > .table
    > tbody
    > tr
    > td {
    white-space: initial;
  }
}

@media (max-width: 1200px) {
  .WorkflowsSupplierDetails-content-table .table-responsive tr td:last-child {
    padding-right: 10px;
  }

  .WorkflowsSupplierDetails-content-table .table-responsive tr td:first-child {
    padding-left: 0;
  }
}

.WorkflowsSupplierDetails button.Dots-icon {
  font-size: 32px;
}

.Bir-report-group .WorkflowsSupplierDetails-title-table button:last-child {
  margin-right: 26px;
}

.Bir-report-group .WorkflowsSupplierDetails-title-table button {
  margin-left: auto !important;
}

.WorkflowsSupplierDetails .Download-export-btn {
  font-size: 20px !important;
  padding: 3px !important;
  color: #ffffff !important;
}

.WorkflowsSupplierDetails .Download-export-btn.btn-default:active:hover,
.WorkflowsSupplierDetails .Download-export-btn.btn-default.active:hover,
.WorkflowsSupplierDetails
  .Download-export-btn.open
  > .dropdown-toggle.btn-default:hover,
.WorkflowsSupplierDetails .Download-export-btn.btn-default:active:focus,
.WorkflowsSupplierDetails
  .Download-export-btn.open
  > .dropdown-toggle.btn-default:focus,
.WorkflowsSupplierDetails .Download-export-btn.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus,
.WorkflowsSupplierDetails .Download-export-btn .dropdown-toggle.btn-default {
  font-size: 20px !important;
  color: #ffffff !important;
  border-color: transparent !important;
  padding: 0 !important;
}

.WorkflowsSupplierDetails .Download-export-btn .dropdown-toggle.btn-default {
  font-size: 20px;
  padding: 0;
  color: #ffffff !important;
}

.WorkflowsSupplierDetails .Download-export-btn.dropdown .caret {
  margin-right: 3px;
  margin-left: 0;
}

.WorkflowsSupplierDetails .Btn-upload-file {
  font-size: 14px !important;
  height: 36px !important;
}

.WorkflowsSupplierDetails .Btn-request-cfr {
  background-color: #e4e4e4 !important;
}

.WorkflowsSupplierDetails .Btn-request-cfr:hover,
.WorkflowsSupplierDetails .Btn-request-cfr:active {
  background-color: #dadada !important;
}

.CFR-btns {
  margin-left: auto;
  margin-right: 26px;
}

.WorkflowsSupplierDetails-content-table ul {
  padding-left: 20px;
}

.WorkflowsSupplierDetails-ccr-score button {
  margin-left: 0 !important;
}

.WorkflowsSupplierDetails-title-table .Title-tooltip {
  margin-left: auto !important;
}

.WorkflowsSupplierDetails-btn-group .icon-ic_downloads:before {
  margin-right: 0;
}

.WorkflowsSupplierDetails-title-table .Download-export-btn {
  background-color: #dddcdc !important;
  color: #333333 !important;
}

.WorkflowsSupplierDetails .Download-dropdown .DropdownDate {
  color: #888888;
  font-size: 12px;
}

.WorkflowsSupplierDetails .Workflow-btn-archive,
.WorkflowsSupplierDetails .Workflow-btn-print {
  font-size: 16px !important;
  padding: 6px 8px !important;
}
