.TagsManagementDropdown #tags-management {
  padding: 0;
  margin-left: 10px;
}

.TagsManagementDropdown .caret {
  display: none;
}

.TagsManagementDropdown .TagsManagementDropdown-Menu {
  width: 300px;
}

.TagsManagementDropdown .Tags-Header {
  color: #b3b3b3;
  padding: 10px;
}

.TagsManagementDropdown .Tags-row {
  padding: 5px 10px;
  display: block;
}

.TagsManagementDropdown .Tags-color-selector {
  display: inline-block;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  background-color: #e6e6e6;
}

.TagsManagementDropdown .Tags-color-selector:hover {
  opacity: 0.6;
}

.TagsManagementDropdown .Tags-color-selector::after {
  content: '';
  position: absolute;
  left: 39%;
  top: 48%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #ffffff;
  clear: both;
}

.TagsManagementDropdown input {
  width: 180px;
  margin: 0 10px;
  display: inline-block;
}

.TagsManagementDropdown .Tags-delete-btn {
  cursor: pointer;
}

.TagsManagementDropdown .TagsColorDropdown .TagsColorDropdown-Menu {
  width: 200px;
}

.TagsManagementDropdown .TagsColorDropdown .Tags-color-container {
  margin: 10px;
}

.TagsManagementDropdown .TagsColorDropdown .Badge {
  width: 38%;
  margin: 10px;
  padding: 5px;
  cursor: pointer;
}

.TagsManagementDropdown .TagsColorDropdown .Badge:hover {
  opacity: 0.7;
}

.TagsManagementDropdown .Tags-create-btn,
.TagsManagementDropdown .Tags-create-btn:hover,
.TagsManagementDropdown .Tags-create-btn:focus {
  background-color: #ffffff;
  text-align: left;
  border: none;
  border-top: 1px solid #d7e0e4;
  border-radius: 0;
  width: 100%;
  height: 45px;
  padding: 0 20px 0 20px;
  color: #009dce;
  font-size: 14px;
  margin-top: 5px;
  font-weight: 600;
}

.TagsManagementDropdown .Tags-create-btn:hover {
  color: #0088b1;
}

.TagsManagementDropdown .icon-ic_plus:before {
  margin-right: 10px;
}
