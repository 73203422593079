.SuppliersTable .table-responsive {
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  overflow-x: visible;
}

.SuppliersTable .table-responsive tbody tr td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.SuppliersTable .table-responsive thead tr th:first-child,
.SuppliersTable .table-responsive tbody tr td:first-child {
  position: sticky;
  left: -20px;
  z-index: 1000;
  background: #ffffff;
}

.SuppliersTable .table-responsive tbody tr td.Tags {
  cursor: pointer;
  color: #ffffff;
}

.SuppliersTable .table-responsive .react-bootstrap-table-editing-cell {
  padding: 0 !important;
}

.SuppliersTable
  .table-responsive
  .react-bootstrap-table-editing-cell
  > div:first-child {
  height: 52px;
}

.SuppliersTable .Supplier-status-tabs {
  margin-top: 70px;
  margin-left: 15px;
}

.SuppliersTable .Supplier-status-tabs .nav-tabs {
  margin-bottom: -10px;
  margin-left: 7px;
}

.SuppliersTable #supplier-status-tabs-tab-filter {
  color: #728d96;
  font-weight: 500;
  width: 200px;
  height: 50px;
  padding-top: 15px;
  padding-left: 15px;
}

@media (max-width: 650px) {
  .SuppliersTable .Supplier-status-tabs .nav-tabs > li {
    width: auto;
    margin-right: 20px;
  }
}

.SuppliersTable .Suppliers-count {
  margin-left: 5px;
}

.SuppliersTable .Reminder-icon {
  cursor: pointer;
  color: #00b2a9;
}

.SuppliersTable .Reminder-icon.disabled {
  cursor: default;
  color: #cacaca;
}

.SuppliersTable .Archive-icon {
  color: #888888;
  cursor: pointer;
}

.SuppliersTable .table {
  margin-top: 0;
  margin-bottom: 30px;
  clear: both;
  table-layout: fixed;
}

.SuppliersTable .table thead th {
  white-space: nowrap;
}

.SuppliersTable .Table-link {
  color: #009dce;
}

.SuppliersTable .Table-link:hover,
.SuppliersTable .Table-link:focus {
  color: #0088b1;
  text-decoration: none;
  outline: none;
}

.SuppliersTable .BtnIconsTable {
  display: inline-block;
  margin-left: 10px;
  background-color: transparent;
  border-radius: 2px;
  padding: 4px;
}

.SuppliersTable .BtnIconsTable:hover {
  display: inline-block;
  margin-left: 10px;
  background-color: #f2f2f2;
  border-radius: 2px;
  padding: 4px;
}

.SuppliersTable .BtnIconsTable i {
  font-size: 22px;
  cursor: pointer;
}

.SuppliersTable .BtnIconsTable i.Screen-icon {
  color: #009dce;
  font-size: 24px;
}

.SuppliersTable .BtnIconsTable i.Screen-icon.disabled {
  color: #cacaca;
}

.SuppliersTable .table > tbody > tr > td.td-actions {
  padding-right: 40px;
}

.SuppliersTable .Delete-supplier-btn {
  cursor: pointer;
  color: #b32c4a;
}

.SuppliersTable .Website {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SuppliersTable .Download-icon {
  color: #15cdc4;
  cursor: pointer;
}

.SuppliersTable .Download-icon.disabled {
  color: rgba(21, 205, 196, 0.5);
  cursor: default;
}

.SuppliersTable .Request-btn {
  color: #009ece;
  cursor: pointer;
}

.SuppliersTable .Request-btn.disabled {
  color: #cacaca;
  cursor: default;
}

.SuppliersTable .icon-ic_question.Icon-question {
  font-size: 18px;
  color: #888888;
}

.SuppliersTable .icon-ic_question.Icon-question:hover {
  font-size: 18px;
  color: #333333;
}

.SuppliersTable .Badge.Tags-badge {
  color: #ffffff;
  margin-right: 5px;
  display: inline;
}

.SuppliersTable .table th:focus {
  outline: none;
}
