.Settings {
  overflow: auto;
}

.Settings .Account,
.Settings .ResetPassword {
  margin: 20px 10px 20px 10px;
  border: 1px solid #d7e0e4;
}

.Settings h2 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.Settings h4 {
  font-size: 14px;
  font-weight: 600;
  color: #003c53;
}

.Settings label,
.Settings button[type='submit'],
.Settings .alert {
  display: block;
  margin-top: 15px;
}

.Settings input,
.Settings .alert,
.Settings .LanguageDropdown {
  max-width: 100%;
}

.Settings .LanguageDropdown {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .Settings .col-xs-12 {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.Settings-tabs {
  margin-top: 30px;
}

.Settings .Title-table {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  border-bottom: 1px solid #d7e0e4;
  padding: 16px 20px;
}

.Settings .Content-table-last {
  padding: 6px 20px 20px 20px;
}

.Settings .Account button,
.Settings .ResetPassword button {
  margin-top: 26px;
}
