.WorkflowsCreateUpdateModal .modal-content {
  border-radius: 0;
  vertical-align: middle;
}

.WorkflowsCreateUpdateModal .Close-modal-icon {
  margin-top: -25px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .WorkflowsCreateUpdateModal:before {
    display: inline-block;
    vertical-align: middle;
    content: ' ';
    height: 30%;
  }
}

.WorkflowsCreateUpdateModal .modal-footer .btn + .btn {
  margin-left: 10px;
}

.WorkflowsCreateUpdateModal .modal-footer button:first-of-type {
  margin-right: 10px;
}
