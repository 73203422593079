.OnboardingSupplierFinished {
  margin-top: 50px;
  margin-bottom: 40px;
  text-align: center;
  padding: 20px;
}

.OnboardingSupplierFinished img {
  margin-bottom: 25px;
}

.OnboardingSupplierFinished p {
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}

.OnboardingSupplierFinished a,
.OnboardingSupplierFinished button {
  margin-top: 30px;
  width: 208px;
}

.OnboardingSupplierFinished a {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .OnboardingSupplierFinished a,
  .OnboardingSupplierFinished button {
    width: 100%;
  }
  .OnboardingSupplierFinished a {
    margin-left: 0;
  }
}
