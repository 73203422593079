.SuppliersColumnSelector {
  display: inline-block;
  margin-top: 0;
  padding: 0 !important;
  font-size: 16px;
  margin-left: 10px !important;
}

@media (max-width: 690px) {
  .SuppliersColumnSelector {
    display: none;
  }
}

.SuppliersColumnSelector .input-control {
  width: 260px;
  margin: 12px 0 12px 0;
}

.SuppliersColumnSelector .dropdown-menu {
  padding: 10px 20px 10px 20px;
}

.SuppliersColumnSelector .Dropdown-title-label {
  color: #888888;
  font-size: 12px;
  margin-bottom: 16px;
}

.SuppliersColumnSelector .btn {
  padding: 6px 8px;
}

.SuppliersColumnSelector > .caret {
  margin-left: 6px;
}

.SuppliersColumnSelector .btn-group > .btn:first-child {
  margin-top: -5px;
}

.SuppliersColumnSelector .dropdown-toggle.btn-default,
.SuppliersColumnSelector .dropdown-toggle.btn-default:hover {
  color: #333333;
  font-size: 14px;
}
