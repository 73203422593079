.Modal-screen .Close-modal-icon {
  margin-top: -23px;
  cursor: pointer;
  color: #005172;
}

.Modal-screen .ModalTitle {
  color: #005172;
}

.Modal-screen .modal-footer {
  border-top: none;
  padding: 0 15px 20px 15px;
}

.Modal-screen .Shareholders-list,
.Modal-screen .AddShareholderRow {
  margin-top: 20px;
  margin-bottom: 20px;
}

.Modal-screen .Shareholders-list p {
  margin-bottom: 15px;
  font-weight: 600;
}
.Modal-screen .Info-text p {
  line-height: 1.6;
}
.Modal-screen .Info-text p:first-child {
  margin-bottom: 30px;
}

.Modal-screen .Btn-add-shareholder,
.Modal-screen .Btn-add-shareholder:hover,
.Modal-screen .Btn-add-shareholder:focus {
  background-color: #f6f7f9;
  border: 1px solid #d7e0e4;
  border-radius: 0;
  min-width: 170px;
  height: 40px;
  padding: 0 20px 0 20px;
  color: #009dce;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.Modal-screen .Btn-add-shareholder:hover {
  color: #0088b1;
}

.Modal-screen .Btn-add-shareholder::before {
  margin-right: 10px;
  font-size: 16px;
}

.Modal-screen .AddShareholder-btn-delete {
  color: #888888;
  line-height: 4;
  cursor: pointer;
  margin: 0 16px;
}

.Modal-screen .AddShareholder-input {
  width: 90%;
  display: inline-block;
}

.Modal-screen .AddShareholderRow i {
  margin: 0 0 0 16px;
}

@media (min-width: 992px) {
  .Modal-screen .modal-dialog {
    width: 500px;
  }
}

.Modal-screen .modal-content {
  border-radius: 0;
}

.Modal-screen .Square-badge {
  margin: 0;
}

.Modal-screen .btn-group {
  margin-bottom: 20px;
}
