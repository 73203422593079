.OnboardingCover .OnboardingCover-cover {
  height: 500px;
  background-attachment: fixed !important;
}

@media (max-width: 630px) and (min-width: 500px) {
  .OnboardingCover .OnboardingCover-cover {
    height: 250px;
  }
}

@media (max-width: 500px) {
  .OnboardingCover .OnboardingCover-cover {
    height: 200px;
  }
}
