@media screen and (max-width: 767px) {
  .table-responsive {
    border: none;
  }
}

.Btn-outline-small,
.Btn-default-small {
  margin-left: 10px !important;
}

.no-border {
  border: none !important;
}

.Image-container {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.Colored-square {
  background: #3e85c1;
  color: #fff;
  display: inline-block;
  line-height: 1.7em;
  font-weight: 400;
  text-align: center;
  width: 100px;
  height: 100px;
  font-size: 46px;
  padding-top: 10px;
}

.Image-overlay {
  position: absolute;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100px;
  height: 100px;
  left: 0;
  top: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Image-overlay .Overlay-text {
  color: #ffffff;
}

.Image-container:hover .Image-overlay {
  opacity: 1;
}
