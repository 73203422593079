.Onboarding-header {
  height: 70px;
  background-color: #003c53;
  display: flex;
  align-items: center;
}

.Onboarding-header .Onboarding-language .dropdown-toggle,
.Onboarding-header .Onboarding-language .dropdown-toggle:hover,
.Onboarding-header .Onboarding-language .dropdown-toggle:active,
.Onboarding-header .Onboarding-language .dropdown-toggle:focus {
  color: #ffffff !important;
}

.Onboarding-header img {
  margin-left: 30px;
  margin-right: auto;
  width: 200px;
}

.Onboarding-header .Onboarding-language {
  float: right;
  right: 30px;
}

.Onboarding-header .Onboarding-language.dropdown.open.btn-group .caret {
  transform: rotate(180deg);
  transition: all 250ms ease;
}

.Onboarding-header .Onboarding-language.dropdown.btn-group .caret {
  transition: all 250ms ease;
}

.Onboarding-header .Onboarding-language.dropdown.btn-group button {
  border-radius: 3px;
  padding: 8px 12px;
  height: 38px;
}

.Onboarding-header .Onboarding-language.dropdown.btn-group button:hover,
.Onboarding-header .Onboarding-language.dropdown.open.btn-group button {
  background-color: #1a4f64;
  border-radius: 3px;
  padding: 8px 12px;
  height: 38px;
}
