.Login-form {
  margin-top: 150px;
}
.btn-forgot-pass {
  margin-top: 4px;
  margin-bottom: 20px;
  padding-right: 0;
  height: 20px;
  color: #009ece;
  font-weight: 600;
}

.btn-forgot-pass:hover {
  color: #009ece;
}

/*OVO JE DA SE MAKNE ZOOM*/
@media (max-width: 768px) {
  .Login-input {
    font-size: 16px;
  }
}
