.Modal-paydex .Close-modal-icon {
  margin-top: -23px;
  cursor: pointer;
  color: #005172;
}

.Modal-paydex .ModalTitle {
  color: #005172;
}

@media (min-width: 992px) {
  .Modal-paydex .modal-dialog {
    width: 500px;
  }
}

.Modal-paydex .modal-content {
  border-radius: 0;
}

.Modal-paydex .Square-badge {
  margin: 0;
}
