.Modal-status .Close-modal-icon {
  margin-top: -23px;
  cursor: pointer;
  color: #005172;
}

.Modal-status .ModalTitle {
  color: #005172;
}

.Modal-status .table th:first-of-type {
  width: 250px;
}

@media (min-width: 992px) {
  .Modal-status .modal-dialog {
    width: 700px;
  }
}

.Modal-status .modal-content {
  border-radius: 0;
}
