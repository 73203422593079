.Statistics-title span {
  font-size: 14px;
}

.RecentActivity-type {
  font-weight: 600;
}

.RecentActivity-company {
  font-weight: 600;
}

.RecentActivity-notification {
  border-bottom: 1px #d7e0e4 solid;
  padding-top: 12px;
}

.RecentActivity-time {
  font-size: 12px;
  color: #728d96;
}

.RecentActivity-notification-message,
.RecentActivity-type,
.RecentActivity-company {
  color: #333333;
}

.RecentActivity-container a {
  text-decoration: none;
}

.RecentActivity-empty {
  border-bottom: 1px #d7e0e4 solid;
  padding-top: 12px;
  padding-bottom: 8px;
}
