.WorkflowsCreate {
  padding: 20px;
  background-color: #ffffff;
  height: calc(100vh - 70px);
  overflow: auto;
}

.WorkflowsCreate-btn-group {
  display: inline-flex;
  align-items: center;
}

.WorkflowsCreate-btn {
  height: 50px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  width: 120px;
}

.WorkflowsCreate-btn-launch,
.WorkflowsCreate-btn-update,
.WorkflowsCreate-btn-cancel {
  margin-left: 10px;
  margin-top: -8px;
}

.WorkflowsCreate-panel.panel.panel-default {
  margin-bottom: 20px;
  padding: 0;
  box-shadow: none;
  border: 1px solid #d7e0e4;
}

.WorkflowsCreate-panel .panel-body {
  padding: 20px;
}

@media (max-width: 768px) {
  .WorkflowsCreate-panel.panel.panel-default {
    margin-bottom: 8px;
  }
}

.WorkflowsCreate-panel .panel-title {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}

.WorkflowsCreate-panel label {
  font-size: 14px;
  margin-bottom: 14px;
  display: block;
}

.WorkflowsCreate-panel .form-control {
  margin-top: 6px;
  margin-bottom: 6px;
  background-image: none;
  appearance: none;
}

.WorkflowsCreate-panel .table-responsive {
  overflow-x: visible;
  overflow-y: visible;
}

.WorkflowsCreate-panel tbody tr:last-child {
  border: none;
}

.WorkflowsCreate-panel .nav-tabs > li > a {
  margin-left: 0;
}

.WorkflowsCreate-suppliers-bulk-textarea {
  min-height: 231px;
  border: 1px solid #d7e0e4;
  border-radius: 2px;
}

.WorkflowsCreate-btn-add,
.WorkflowsCreate-btn-add:hover,
.WorkflowsCreate-btn-add:focus {
  background-color: #f6f7f9;
  border: 1px solid #d7e0e4;
  border-radius: 0;
  min-width: 170px;
  height: 45px;
  padding: 0 20px 0 20px;
  color: #009dce;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.WorkflowsCreate-btn-add:hover {
  color: #0088b1;
}

.WorkflowsCreate-btn-add.icon-ic_plus:before {
  margin-right: 16px;
  font-size: 16px;
}

.WorkflowsCreate-btn-add-title {
  margin-left: 10px;
  min-width: 134px;
}

.WorkflowsCreate-btn-add-title,
.WorkflowsCreate-btn-add-title:hover,
.WorkflowsCreate-btn-add-title:focus {
  min-width: 134px;
}

.WorkflowsCreate-btn-delete {
  color: #888888;
  line-height: 4;
  cursor: pointer;
  margin: 0 16px;
  opacity: 0;
}

.WorkflowsCreate-btn-drag {
  color: #b3b3b3;
  cursor: all-scroll;
  float: right;
  opacity: 0;
}

.WorkflowsCreate-btn-drag.icon-ic_grip-horizontal:before {
  font-size: 16px;
}

.WorkflowsCreate-cover {
  width: 100%;
  height: 180px;
  border-radius: 2px;
  margin: 3px;
  padding: 0;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.WorkflowsCreate-no-cover-image {
  background-color: #d7e0e4;
  background-image: url('../../_images/bg-cover-onboarding.jpg');
  background-position: left 0px top -65px;
}

.WorkflowsCreate-upload-btn,
.WorkflowsCreate-upload-btn:focus {
  margin-top: 10px;
  background: none;
  border: none;
}

.WorkflowsCreate-dropdown.form-control.Dropdown-form-control,
.WorkflowsCreate-dropdown.form-control.Dropdown-form-control:focus {
  text-transform: none !important;
}

.WorkflowsCreate-error {
  margin-top: 20px;
}

.WorkflowsCreate-no-questions {
  padding: 20px;
  font-weight: 600;
}

.WorkflowsCreate-message {
  min-height: 200px;
}

.WorkflowsCreate-report-type {
  display: inline-block !important;
  margin-left: 10px;
}

@media (max-width: 767px) {
  .WorkflowsCreate-btn-launch,
  .WorkflowsCreate-btn-update,
  .WorkflowsCreate-btn-cancel,
  .WorkflowCreate-btn-delete {
    display: none;
  }

  .WorkflowsCreate .Dots-icon {
    font-size: 32px;
    color: #005172;
    margin-top: -10px;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .WorkflowsCreate .Dots-icon {
    display: none;
  }
}

.WorkflowsCreate-btn-group .dropdown.open #workflow-create-actions:active:hover,
.WorkflowsCreate-btn-group .dropdown.open #workflow-create-actions:active:focus,
.WorkflowsCreate-btn-group .dropdown.open #workflow-create-actions.active:focus,
.WorkflowsCreate-btn-group .dropdown.open > #workflow-create-actions:focus,
.WorkflowsCreate-btn-group .dropdown.open #workflow-create-actions:active.focus,
.WorkflowsCreate-btn-group .dropdown.open #workflow-create-actions.active.focus,
.WorkflowsCreate-btn-group .dropdown.open > #workflow-create-actions.focus,
.WorkflowsCreate-btn-group .dropdown.open > #workflow-create-actions:hover {
  color: #003e57;
}

#accordion-create-workflow {
  margin-top: 40px;
}

@media (max-width: 650px) {
  .WorkflowsCreate .panel-group .panel {
    margin-top: 20px;
  }

  .WorkflowsCreate .Dots-icon {
    margin-top: 35px;
  }

  #accordion-create-workflow {
    padding-top: 20px;
  }
}

@media (max-width: 768px) {
  .WorkflowsCreate {
    padding: 10px;
  }
}

.WorkflowsCreate .Cover-overlay {
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 180px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.WorkflowsCreate .Cover-overlay .Cover-overlay-text {
  color: #ffffff;
}

.WorkflowsCreate .WorkflowsCreate-cover:hover .Cover-overlay {
  opacity: 1;
}

.WorkflowsCreate .icon-ic_radio:before {
  margin-right: 12px;
}

.WorkflowsCreate .icon-ic_checkbox:before {
  margin-right: 12px;
}

.WorkflowsCreate .question {
  margin: 15px 0 5px 0;
}

.WorkflowsCreate .input-control {
  margin-top: 15px;
}

.WorkflowsCreate-table tr td {
  width: 33.33%;
}

.table tr.no-border {
  border-bottom: none;
}

#workflow-create-suppliers .table > tbody > tr > td.Field-address,
#workflow-create-suppliers .table > tbody > tr > td.Field-taxID,
#workflow-create-suppliers .table > tbody > tr > td.Field-country {
  padding-top: 0;
  padding-bottom: 0;
}

#workflow-create-suppliers .table > tbody > tr > td.Field-company-name,
#workflow-create-suppliers .table > tbody > tr > td.Field-full-name,
#workflow-create-suppliers .table > tbody > tr > td.Field-email {
  padding-bottom: 0;
  padding-top: 10px;
}

#workflow-create-suppliers .table > tbody > tr > td.Field-sic,
#workflow-create-suppliers .table > tbody > tr > td.Field-phone,
#workflow-create-suppliers .table > tbody > tr > td.Field-duns-number {
  padding-bottom: 10px;
  padding-top: 0;
}

.WorkflowCreate-btn-delete {
  font-size: 16px;
  padding: 6px 8px;
  margin-top: -7px;
}

.WorkflowsCreate-panel .panel-title > a {
  display: block;
  padding: 20px 20px;
}

.WorkflowsCreate-panel.panel,
.WorkflowsCreate-panel .panel-heading {
  padding: 0;
}

.WorkflowsCreate-panel .icon-ic_expand-arrow {
  float: right;
  margin-right: 10px;
  margin-left: 30px;
  transition: all 250ms ease;
  width: 22px;
  transform-origin: center center;
  transform: rotate(180deg);
}

.WorkflowsCreate-panel .collapsed .icon-ic_expand-arrow {
  transform: rotate(0deg);
}

.WorkflowsCreate-panel .icon-ic_expand-arrow:before {
  font-size: 10px;
}

.WorkflowsCreate-cover-title {
  font-weight: 600;
}

.question.smooth-dnd-draggable-wrapper.active {
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid transparent;
}

.smooth-dnd-container {
  margin: 0 -20px 20px -20px;
}

.question.smooth-dnd-draggable-wrapper {
  padding-left: 20px;
  background-color: white;
  min-height: 87px;
  margin-top: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #d7e0e4;
}

.WorkflowsCreate-panel .smooth-dnd-ghost.question.smooth-dnd-draggable-wrapper {
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.3);
}

.WorkflowsCreate-panel
  .smooth-dnd-container.vertical
  > .smooth-dnd-draggable-wrapper {
  overflow: visible;
}

.question-type-buttons,
.Question-input {
  display: flex;
}

.Question-number {
  margin: 20px 20px 0 0;
  position: relative;
  width: 20px;
}

.Question-number span,
.WorkflowsCreate-btn-drag {
  position: absolute;
}

.smooth-dnd-draggable-wrapper:hover .Question-number span {
  opacity: 0;
}

.smooth-dnd-draggable-wrapper .FileDescriptor-btn-delete {
  opacity: 0;
}

.smooth-dnd-draggable-wrapper:hover .WorkflowsCreate-btn-drag,
.smooth-dnd-draggable-wrapper:hover .WorkflowsCreate-btn-delete:not(.disabled),
.WorkflowsCreate-table tr:hover .WorkflowsCreate-btn-delete:not(.disabled),
.smooth-dnd-draggable-wrapper:hover .FileDescriptor-btn-delete {
  opacity: 1;
}

@media (max-width: 991px) {
  .WorkflowsCreate .form-control.dropdown.btn-group {
    margin-left: 40px;
  }
}

.Question-options {
  margin-left: 60px;
  display: flex;
}

.WorkflowsCreate-btn-add-option,
.WorkflowsCreate-btn-add-option:hover,
.WorkflowsCreate-btn-add-option:active {
  margin-top: 20px;
  margin-left: 60px;
}

.WorkflowsCreate-panel .smooth-dnd-draggable-wrapper {
  padding-bottom: 20px;
}

.WorkflowsCreate-btn-delete.disabled {
  opacity: 0;
  cursor: default;
}

.WorkflowsCreate .Icon-preview {
  color: #888888;
}

.WorkflowsCreate .Icon-preview:hover,
.WorkflowsCreate .Icon-preview:active {
  color: #777777;
}

.WorkflowsCreate .Title-mark {
  font-weight: 600;
  font-size: 18px;
}

.WorkflowsCreate .Question-attachment {
  margin: 10px 0px 10px 40px;
}

.WorkflowsCreate .Question-attachment .FileUpload.uploading {
  margin-top: 10px;
  margin-bottom: 0;
}

.Required-question {
  display: flex;
  align-items: center;
}

.Required-question span.Required-label {
  padding-left: 20px;
  padding-right: 20px;
}

.Field-email .Multiselect__control {
  height: 45px;
  position: absolute;
  transition: all 200ms;
  margin-top: -22px;
}

.Field-email {
  height: 45px;
}

.Field-email .Multiselect__control.Multiselect__control--is-focused,
.Field-email
  .Multiselect__control.Multiselect__control--is-focused
  .Multiselect__value-container {
  z-index: 1001;
  min-height: 43px;
  max-height: 120px;
  height: auto;
}

.Field-email
  .Multiselect__control.Multiselect__control--is-focused
  .Multiselect__value-container {
  padding: 4px 8px 6px 8px;
}

.Multiselect__value-container div:last-child,
.Multiselect__value-container div:last-child .Multiselect__input,
.Multiselect__value-container div:last-child .Multiselect__input input {
  height: 28px;
  margin-top: -1px;
}

.Field-email .Multiselect__input,
.Field-email .Multiselect__input input {
  height: 28px !important;
  margin-top: -1px !important;
}

.Multiselect__control.Multiselect__control--is-focused {
  border-color: #66afe9 !important;
  border-radius: 2px !important;
  outline: 0 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6) !important;
}
