.Onboarding-404 {
  height: 100vh;
}

.Onboarding-main {
  background-color: #fff;
  margin-top: -300px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 50px;
}

@media (max-width: 1200px) and (min-width: 768px) {
  .Onboarding-main {
    margin-top: -400px;
  }
}

.OnboardingCover-logo {
  position: relative;
  display: inline-block;
  width: 150px;
  height: 150px;
  background-color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.button-content {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

@media (max-width: 630px) and (min-width: 500px) {
  .OnboardingCover-logo {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 500px) {
  .OnboardingCover-logo {
    width: 120px;
    height: 120px;
  }
}

.OnboardingCover-logo.OnboardingCover-no-logo {
  background: #3e85c1;
  color: #fff;
  font-weight: 400;
  text-align: center;
  font-size: 60px;
  vertical-align: middle;
  line-height: 150px;
}

@media (max-width: 630px) and (min-width: 500px) {
  .OnboardingCover-logo.OnboardingCover-no-logo {
    box-sizing: border-box;
    font-size: 50px;
    line-height: 140px;
  }
}

@media (max-width: 500px) {
  .OnboardingCover-logo.OnboardingCover-no-logo {
    box-sizing: border-box;
    font-size: 40px;
    line-height: 105px;
  }
}

.How-it-works {
  display: flex;
  flex-direction: column;
  background-color: #f4f5fb;
  margin-top: 100px;
}

.How-it-works-content .title {
  font-size: 30px;
  font-weight: 600;
  color: #333333;
  text-align: center;
  margin-bottom: 40px;
}

.How-it-works-content {
  margin-top: 80px;
  margin-bottom: 100px;
}

.How-it-works .card {
  background-color: #ffffff;
  padding: 50px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  min-height: 320px;
}

.How-it-works .card.no-payment {
  min-height: 368px;
}

.How-it-works .card .card-content img {
  margin-right: 16px;
}

.How-it-works .card .card-content .card-title {
  font-size: 20px;
  font-weight: 600;
  display: inline-block;
}

.How-it-works .card .card-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.How-it-works .card .card-text {
  margin-top: 20px;
  font-size: 18px;
  line-height: 1.6;
}

.Section-text {
  font-size: 18px;
  line-height: 1.6;
  text-align: center;
  display: block;
}

@media (max-width: 768px) {
  .Onboarding-main {
    margin-top: 0px;
  }

  .How-it-works {
    margin-top: 0;
  }

  .How-it-works-content {
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .How-it-works-content .title {
    margin-bottom: 16px;
  }

  .row .How-it-works-content {
    margin-bottom: 0;
  }
}
