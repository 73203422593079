.OnboardingSupplierPayment h1 {
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  color: #333333;
}

.OnboardingSupplierPayment label {
  color: #333333;
  display: inline;
}

.OnboardingSupplierPayment .SecurityInformation,
.OnboardingSupplierPayment .InvoiceDownloadButton {
  margin-top: 30px;
}

.InvoiceContent p {
  margin-top: 10px;
}

.Upload-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
}

.Browse-file {
  font-weight: 600;
  color: #00b5e6;
  margin-top: 10px;
  margin-bottom: 4px;
}

.UploadContent img {
  margin-left: 30px;
}

.Min-size {
  color: #888888;
  font-size: 13px;
}

.SecurityInformation {
  text-align: center;
  color: #888888;
  font-size: 13px;
}

.SecurityInformation img {
  margin-right: 10px;
  margin-top: -5px;
}

h5.PaymentReference-form-section {
  font-weight: 700;
  margin-bottom: 20px;
}

.PaymentReference-row {
  margin-bottom: 40px;
}

.PaymentReference-row .dropdown-menu {
  overflow-y: scroll;
  max-height: 200px;
  width: 100%;
}

.OnboardingSupplierPaymentProof .Btn-submit {
  margin-bottom: 30px;
  width: 100%;
}

.OnboardingSupplierPaymentProof h5 {
  font-weight: 700;
}

.OnboardingSupplierPayment .FileUpload {
  height: 50px;
  margin-bottom: 20px;
}

.OnboardingSupplierPayment .FileUpload .Btn-outline-small {
  margin-left: 0 !important;
}

.OnboardingSupplierPayment .FileUpload.uploading {
  margin-top: 15px;
  margin-bottom: 20px;
}

.OnboardingSupplierPaymentProof .Btn-default-small {
  margin-left: 0 !important;
}

h5.PaymentReference {
  margin-bottom: 26px;
  margin-top: 30px;
}

.OnboardingSupplierPaymentProof .Payment {
  padding-right: 40px;
}

.OnboardingSupplierPaymentProof .PaymentProof {
  margin-top: 40px;
}

.OnboardingSupplierPaymentProof .PeruForm p {
  display: inline-block;
}

.OnboardingSupplierPaymentProof .PeruForm label ~ p {
  margin-left: 5px;
}

.OnboardingSupplierPayment .Btn-default-primary {
  margin-top: 20px;
}

.OnboardingSupplierPayment .Terms-checkbox {
  font-size: 14px;
}
