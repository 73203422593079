.CountryDropdown__control {
  height: 45px;
  width: 100%;
  border: 1px solid #d7e0e4;
  border-radius: 2px !important;
  box-shadow: none;
  font-size: 14px;
  color: #555;
}

.CountryDropdown__control.CountryDropdown__control--is-focused {
  border-color: #66afe9;
  border-radius: 2px !important;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.CountryDropdown__menu {
  border-radius: 2px !important;
}

.CountryDropdown__placeholder {
  color: #999999 !important;
}

.CountryDropdown__option,
.CountryDropdown__single-value {
  color: #333333 !important;
  text-transform: capitalize;
}

.CountryDropdown__option.CountryDropdown__option--is-focused {
  background-color: #f2f2f2;
  color: #333333;
}

.CountryDropdown__option.CountryDropdown__option--is-selected {
  background-color: #e4e4e4;
  color: #333333;
}

.CountryDropdown__input input {
  height: 32px;
}
