.WorkflowsList {
  padding: 40px;
  height: calc(100vh - 70px);
  background-color: #ffffff;
}

.WorkflowsList-content {
  margin-top: 20px;
  margin-bottom: 50px;
}

.WorkflowsList-create-btn {
  margin-right: 0;
  margin-top: -15px;
}

.WorkflowsList-download-btn {
  margin-top: -15px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .WorkflowsList .Btn-default > .icon-ic_plus:before {
    margin-right: 2px;
  }

  .WorkflowsList-create-btn {
    padding: 6px 16px;
    margin-right: -6px;
  }

  .WorkflowsList {
    padding: 0 10px;
    margin-top: -75px;
  }

  .WorkflowsListItem {
    margin-bottom: 16px;
    border: 1px solid #d7e0e4;
    height: 200px;
  }

  .WorkflowsList-sort-btn {
    margin-top: 60px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .WorkflowsListItem {
    height: 200px;
  }
  .WorkflowsListItem-image-container {
    height: 200px;
  }
}

.WorkflowsList-content h3 {
  line-height: 1.3;
}

.WorkflowsList-download-btn i {
  margin-right: 8px;
  font-size: 16px;
}

.List-btn-group {
  margin-right: -25px;
  margin-top: -20px;
  margin-bottom: 20px;
}
