.OnboardingQuestionnaire-header {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 30px;
}

.OnboardingQuestionnaire-question {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
}

.OnboardingQuestionnaire-free-text {
  margin-bottom: 40px;
  min-height: 80px;
  max-width: 800px;
}

.OnboardingQuestionnaire .FileDescriptor {
  line-height: 2.7;
  min-height: 40px;
}

.OnboardingQuestionnaire .FileUpload {
  margin-bottom: 25px;
  height: 40px;
}

.OnboardingQuestionnaire .FileUpload button {
  margin-left: 0 !important;
}

.OnboardingQuestionnaire .FileUpload.uploading {
  margin-top: 25px;
  height: 40px;
}

.OnboardingQuestionnaire-form-error {
  margin-top: 20px;
}

.OnboardingQuestionnaire-section {
  font-size: 20px;
  font-weight: 600;
  color: #005172;
}

.OnboardingQuestionnaire .form-group.OnboardingQuestionaire-input-control {
  display: inline-block;
}
