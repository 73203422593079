.OnboardingIntroduction .content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.OnboardingIntroduction .OnboardingIntroduction-welcome-message {
  font-size: 36px;
  font-weight: 600;
  color: #333333;
  margin-left: 20px;
  line-height: 1.3;
}

.OnboardingIntroduction .OnboardingIntroduction-description {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin-top: 40px;
}

.OnboardingIntroduction .OnboardingIntroduction-description-welcome {
  border: none;
  background: none;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  font-family: 'Open Sans';
  padding: 0;
  margin-top: 25px;
  white-space: pre-line;
}

.OnboardingIntroduction .OnboardingIntroduction-hr {
  border: 1px solid #005172;
  margin-top: 40px;
  margin-left: 62px;
  width: 150px;
}

.OnboardingIntroduction .OnboardingIntroduction-before-you-start {
  font-size: 18px;
  font-weight: 600;
  color: #005172;
  margin-left: 80px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.OnboardingIntroduction .OnboardingIntroduction-step {
  margin-left: 80px;
  color: #005172;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.OnboardingIntroduction .OnboardingIntroduction-step-title {
  margin-left: 10px;
  color: #005172;
  font-size: 18px;
  font-weight: 500;
}

.OnboardingIntroduction .OnboardingIntroduction-step-description {
  margin-left: 80px;
  margin-right: 80px;
  color: #005172;
  font-size: 16px;
  font-weight: 400;
}

.OnboardingIntroduction .OnboardingIntroduction-step-margins {
  margin-top: 40px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .OnboardingIntroduction {
    padding: 40px 15px;
  }

  .OnboardingIntroduction .content {
    flex-direction: column;
  }

  .OnboardingIntroduction .OnboardingIntroduction-welcome-message {
    font-size: 30px;
    margin-top: 20px;
    margin-left: 0;
    line-height: 1.25;
  }

  .OnboardingCover-logo {
    width: 200px;
    height: 200px;
  }

  .Onboarding-main {
    margin-top: 70px;
  }
}
