.Modal-ratings .Close-modal-icon {
  margin-top: -23px;
  cursor: pointer;
  color: #005172;
}

.Modal-ratings .Financial-description {
  margin-bottom: 30px;
}

.Modal-ratings .Modal-table-heading {
  font-size: 14px;
  font-weight: 600;
}
.Modal-ratings .Modal-table-heading.heading-center {
  text-align: center;
}

.Modal-ratings .ModalTitle {
  color: #005172;
}

.Modal-ratings .Modal-paragraph-title {
  font-size: 16px;
  font-weight: 600;
}

@media (min-width: 992px) {
  .Modal-ratings .modal-dialog {
    width: 800px;
  }
}

.Modal-ratings .modal-content {
  border-radius: 0;
}

.Modal-ratings .Modal-table-Risk.table > tbody > tr > td,
.Modal-ratings .Modal-table-Risk.table > thead > tr > th {
  padding-right: 40px;
}

.Modal-ratings .Modal-table-ratings.table > tbody > tr > td {
  padding-right: 16px;
}

.Modal-ratings .table > tbody > tr > td.Risk-indicator {
  padding-left: 16px;
}
